var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stage-current" }, [
    _c("img", {
      staticClass: "stage-current__flag",
      attrs: { src: "/assets/img/flag/" + _vm.stageFlag + ".svg", alt: "" },
    }),
    _c("span", { staticClass: "stage-current__label" }, [
      _vm._v(" " + _vm._s(_vm.label) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }