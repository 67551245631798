<template>
  <div class="stage-current">
    <img
      :src="`/assets/img/flag/${stageFlag}.svg`"
      class="stage-current__flag"
      alt="" />
    <span class="stage-current__label">
      {{ label }}
    </span>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
import { MileStageType } from '@/constants/enums';
export default defineComponent({
  props: {
    id: {
      type: [String, Number],
      default: ''
    },

    label: {
      type: String,
      default: ''
    }
  },

  setup: (props) => {
    const stageFlag = computed(() => {
      if (!props.id) return;
      const mileStageId = MileStageType.getMileStageTypeKeyById(props.id);
      return MileStageType.getTypeName(mileStageId)
        .replace(' ', '')
        .toLowerCase();
    });

    return {
      stageFlag
    };
  }
});
</script>

<style lang="scss" scoped>
.stage-current {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &__flag {
    margin-right: 5px;
    width: 24px;
    height: 24px;
  }

  &__label {
    font-size: 14px;
  }

  &__help {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0.4rem 0.8rem;
  }
}
</style>
